@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rubik";
  src: url("./Rubik-Light.ttf") format("woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
body {
  font-family: "Rubik";
  overflow-x: hidden;
  background-color: #fff;
  letter-spacing: 0.2px;
}

body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #eee;
}

input::placeholder {
  color: #292929;
}

li {
  list-style: none;
}

*:focus {
  outline: none;
}

*::selection {
  color: #eee;
  background-color: #292929;
}

.total-price-icon {
  position: relative;
  animation: moveLeftRight 2s infinite alternate;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
